<template>
    <v-container fluid>
        <v-card
            :loading="loading"
            class="mt-5 mx-auto"
            elevation="1"
            outlined
            max-width="650px"
        >
            <Page-Header :title="$t('stock.stocks')" icon="mdi-archive-outline">
                <template v-slot:buttons>
                    <v-btn
                        :loading="loading"
                        color="primary darken-1"
                        @click.native="dialog = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("add") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-row class="pa-5">
                <v-col cols="12">
                    <v-text-field
                        outlined
                        dense
                        v-model="search"
                        :label="$t('search')"
                        flat
                        :loading="loading"
                        hide-details
                        clearable
                        clear-icon="mdi-close-circle-outline"
                        append-icon="mdi-magnify"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <!--Save popup-->
            <v-dialog v-model="dialog" persistent max-width="500px">
                <v-toolbar dark color="primary darken-1">
                    <v-card-title>
                        <span>{{
                            (editedIndex === -1 ? $t("add") : $t("edit")) +
                                " " +
                                $t("stock.stock")
                        }}</span>
                    </v-card-title>
                </v-toolbar>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            outlined
                                            hide-details
                                            dense
                                            v-model="editedItem.stockName"
                                            :rules="rules"
                                            :label="$t('stock.stockName')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-autocomplete
                                            outlined
                                            dense
                                            hide-details
                                            @change="generateCode"
                                            v-model="editedItem.stockParentGuid"
                                            :items="stocksTreeList"
                                            item-value="stockGuid"
                                            item-text="stockName"
                                            :label="$t('stock.stockParent')"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            outlined
                                            dense
                                            hide-details
                                            readonly
                                            :rules="rules"
                                            v-model="editedItem.stockCode"
                                            :label="$t('stock.stockCode')"
                                        >
                                            <template slot="append">
                                                <v-tooltip
                                                    top
                                                    transition="fab-transition"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-icon
                                                            color="primary"
                                                            rotate
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="
                                                                generateCode
                                                            "
                                                        >
                                                            mdi-refresh
                                                        </v-icon>
                                                    </template>
                                                    <span>{{
                                                        $t(
                                                            "accountTree.autoCode"
                                                        )
                                                    }}</span>
                                                </v-tooltip>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                color="primary darken-1 white--text"
                                @click="save"
                                :disabled="!valid"
                                :min-width="100"
                            >
                                <v-icon>mdi-content-save-outline</v-icon>
                                {{ $t("save") }}
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn text color="red" @click="close">
                                {{ $t("cancel") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>
            <!--Delete popup-->
            <confirm-dialog
                :openDialog="dialogDelete"
                :onClicked="deleteItemConfirm"
                :onClose="closeDelete"
                toolBarColor="red darken-2"
            ></confirm-dialog>
            <!--<v-divider> </v-divider>-->
            <!--Search-->
            <v-treeview
                v-if="stocksTree"
                ref="treeReference"
                :active.sync="active"
                :items="stocksTree"
                :open.sync="open"
                :search="search"
                class="px-5 pb-5"
                activatable
                open-on-click
                rounded
                item-text="stockName"
                item-key="stockGuid"
                item-children="children"
            >
                <template v-slot:prepend="{}">
                    <v-icon> mdi-domain </v-icon>
                </template>

                <template v-slot:label="{ item }">
                    <v-row>
                        <v-col align-self="center">
                            {{ item.stockCode }} - {{ item.stockName }}
                        </v-col>
                        <v-col cols="auto" class="">
                            <v-menu bottom left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-if="isInRole(56)">
                                        <v-btn text @click="addItem(item)">
                                            <v-list-item-icon>
                                                <v-icon small class="mx-1">
                                                    mdi-plus
                                                </v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>{{
                                                $t("add")
                                            }}</v-list-item-title>
                                        </v-btn>
                                    </v-list-item>
                                    <v-list-item v-if="isInRole(56)">
                                        <v-btn text @click="editItem(item)">
                                            <v-list-item-icon>
                                                <v-icon small class="mx-1">
                                                    mdi-pencil
                                                </v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>{{
                                                $t("edit")
                                            }}</v-list-item-title>
                                        </v-btn>
                                    </v-list-item>
                                    <v-list-item v-if="isInRole(57)">
                                        <v-btn text @click="deleteItem(item)">
                                            <v-list-item-icon>
                                                <v-icon
                                                    color="red"
                                                    small
                                                    class="mx-1"
                                                >
                                                    mdi-delete-outline
                                                </v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>{{
                                                $t("delete")
                                            }}</v-list-item-title>
                                        </v-btn>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col>
                    </v-row>
                </template>
            </v-treeview>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: { ConfirmDialog, PageHeader },
    data() {
        return {
            active: [],
            open: [],
            valid: true,
            loading: true,
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            branches: [],
            stocksTreeList: [],
            stocksTree: [],
            editedItem: {
                stockGuid: null,
                stockCode: null,
                stockParentGuid: null,
                stockName: null
            },
            defaultItem: {
                stockGuid: null,
                stockCode: null,
                stockParentGuid: null,
                stockName: null
            },
            rules: [value => !!value || this.$t("ThisFieldIsRequired")]
        };
    },
    created() {
        if (!this.isInRole(55)) this.redirectUnauthorizedUsers();
        this.loading = true;
        this.refreshTable();
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    methods: {
        generateCode() {
            if (
                this.editedItem.stockParentGuid == undefined ||
                this.editedItem.stockParentGuid == null ||
                this.editedItem.stockParentGuid == ""
            ) {
                this.editedItem.stockParentGuid =
                    "00000000-0000-0000-0000-000000000000";
            }

            axios
                .get(
                    "Stocks/GenerateCode?id=" + this.editedItem.stockParentGuid
                )
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.editedItem.stockCode = response.data.data;
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                });
        },
        addItem(item) {
            if (item) {
                this.editedItem.stockParentGuid = item.stockGuid;
                if (item.stockGuid) this.generateCode();
            }
            setTimeout(() => {
                this.dialog = true;
            }, 100);
        },
        editItem(item) {
            this.editedIndex = this.branches.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.branches.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            var Item = this.branches[this.editedIndex];
            axios
                .delete("Stocks/Delete?id=" + Item.stockGuid)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                    this.refreshTable();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                });

            this.closeDelete();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        refreshTable() {
            try {
                axios
                    .get("Stocks/Get")
                    .then(response => {
                        this.stocksTree = [];
                        this.stocksTreeList = [];
                        this.branches = response.data.data;

                        var general = {
                            stockGuid: "00000000-0000-0000-0000-000000000000",
                            stockName: this.$t("stock.mainStock")
                        };
                        this.stocksTreeList.push(general);

                        for (var i = 0; i < this.branches.length; i++) {
                            var tree = this.branches[i];

                            var children = this.branches.filter(f => {
                                return f.stockParentGuid == tree.stockGuid;
                            });

                            if (children.length > 0) {
                                children.forEach(child => {
                                    var childNode = {
                                        ...tree,
                                        item: child,
                                        vnode: null
                                    };
                                    this.$refs.treeReference.nodes[
                                        child.stockGuid
                                    ] = childNode;
                                });
                                tree.children = children;
                            }

                            this.stocksTreeList.push(tree);

                            if (
                                this.branches[i].stockParentGuid ==
                                "00000000-0000-0000-0000-000000000000"
                            ) {
                                this.stocksTree.push(tree);
                            }
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        save() {
            try {
                if (this.$refs.form.validate()) {
                    if (this.editedIndex == -1) {
                        this.editedItem.stockGuid =
                            "00000000-0000-0000-0000-000000000000";
                    }

                    if (this.editedItem.stockParentGuid == undefined) {
                        this.editedItem.stockParentGuid =
                            "00000000-0000-0000-0000-000000000000";
                    }

                    axios
                        .post("Stocks/Save", this.editedItem)
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                                this.close();
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }
                            this.refreshTable();
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );

                            this.refreshTable();
                            console.log(e);
                        });
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>
